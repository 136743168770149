import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const getData = graphql`
  query {
    site {
      siteMetadata {
        siteTitle: title
        siteDesc: description
        image
        author
        siteUrl
        twitterUsername
      }
    }
    config: allContentfulSiteConfiguration {
      edges {
        node {
          keywords
        }
      }
    }
  }
`

const SEO = ({ pageTitle, pageDescription, canonicalUri, pageKeywords, pageImageUrl, pageImageWidth, pageImageHeight }) => {
  const { site, config } = useStaticQuery(getData)

  const {
    siteDesc,
    siteTitle,
    siteUrl,
    image,
    twitterUsername,
  } = site.siteMetadata

  const {
    keywords,
  } = config.edges[0].node
  
  const truncate = (str) => {
    let truncString = str.replace(/\u00A0/g, '');
    return truncString.length > 160 ? truncString.substring(0, 160) + "..." : truncString;
}
  const description = truncate(pageDescription || siteDesc)
  console.log()
  const title = pageTitle==="Home"? siteTitle : `${pageTitle} | ${siteTitle}`
  const canonical = canonicalUri ?siteUrl + canonicalUri :  siteUrl 
  const allKeywords = pageKeywords ? pageKeywords + "," + keywords.join(",") : keywords.join(",")
  let imageUrl = `${siteUrl}${image}`
  let width = "2160"
  let height = "1440"
  if(pageImageUrl){
    imageUrl = pageImageUrl
    width = pageImageWidth
    height = pageImageHeight
  }
  return (
    <Helmet htmlAttribute={{ lang: "en" }} title={title}>
      <meta name="description" content={description} />
      <meta name="image" content={image} />
      <meta name="keywords" content={allKeywords} />
      <link rel="canonical" href={canonical} />
      {/* Facebook Card */}
      <meta property="og:locale" content="en_AU" />
      <meta property="og:url" content={canonical} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description}/>
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:width" content={width} />
      <meta property="og:image:height" content={height} />
      

      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
    </Helmet>
  )
}

export default SEO
